<template>
  <div class="g11">
    <div class="control">
      <el-button @click="save">保存</el-button>
    </div>
    <div class="excel-container" ref="excelContainer"></div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import auth from '@/common/auth'
import utility from '@/common/utility'
import { mapGetters } from 'vuex'

export default {
  methods: {
    async save () {
      this.$loading()

      // 处理数据
      const sheet = this.workbook.getActiveSheet()

      const jsonData = []
      const rowCount = sheet.getRowCount()
      const columnIndexMap = {
        valid: 19,
        meterageListGuid: 20,
        currentYearNum: 9,
        currentYearAmount: 10,
        currentYearPercent: 11,
        currentMonthNum: 12,
        currentMonthAmount: 13,
        currentMonthPercent: 14,
        totalMonthNum: 15,
        totalMonthAmount: 16,
        totalMonthPercent: 17
      }

      for (let rowIndex = 2; rowIndex < rowCount; ++rowIndex) {
        if (sheet.getCell(rowIndex, columnIndexMap.valid).value() !== 1) {
          continue
        }
        jsonData.push({
          g11Guid: utility.getUuid(),
          tenderGuid: auth.getUserInfo().tenderGuid,
          planStatExaminationGuid: this.planStatExaminationGuid,
          meterageListGuid: sheet.getCell(rowIndex, columnIndexMap.meterageListGuid).value(),
          currentYearNum: sheet.getCell(rowIndex, columnIndexMap.currentYearNum).value(),
          currentYearAmount: sheet.getCell(rowIndex, columnIndexMap.currentYearAmount).value(),
          currentYearPercent: sheet.getCell(rowIndex, columnIndexMap.currentYearPercent).value(),
          currentMonthNum: sheet.getCell(rowIndex, columnIndexMap.currentMonthNum).value(),
          currentMonthAmount: sheet.getCell(rowIndex, columnIndexMap.currentMonthAmount).value(),
          currentMonthPercent: sheet.getCell(rowIndex, columnIndexMap.currentMonthPercent).value(),
          totalMonthNum: sheet.getCell(rowIndex, columnIndexMap.totalMonthNum).value(),
          totalMonthAmount: sheet.getCell(rowIndex, columnIndexMap.totalMonthAmount).value(),
          totalMonthPercent: sheet.getCell(rowIndex, columnIndexMap.totalMonthPercent).value(),
          createTime: utility.getCurrentTime(),
          year: this.year,
          month: this.month
        })
      }

      try {
        const postResult = await axios.post(`${config.restHost}/g11/batchAdd`, jsonData, {
          headers: {
            Authorization: `bearer ${auth.getToken()}`
          }
        })

        if (postResult.data.code === 1) {
          this.$message.success('保存成功')
          await this.init()
        } else {
          this.$message.error('保存失败,数据存在问题')
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.$loading().close()
      }
    },
    async init () {
      this.$loading()

      // 加载excel模版
      const excelTemplate = await axios.get('/excel/G11Template.xlsx', {
        responseType: 'blob'
      })
      this.excelIo = new GC.Spread.Excel.IO()
      this.excelIo.open(excelTemplate.data, json => {
        if (!this.workbook) {
          this.workbook = new GC.Spread.Sheets.Workbook(this.$refs.excelContainer)
        }
        this.workbook.fromJSON(json)
      })

      // 加载清单
      this.meterageLists = (await axios.get(`${config.restHost}/g11/meterageList`, {
        params: {
          tenderGuid: auth.getUserInfo().tenderGuid,
          year: this.year,
          month: this.month
        },
        headers: {
          Authorization: `Bearer ${auth.getToken()}`
        }
      })).data

      console.log(this.meterageLists, this.workbook)

      const array = this.meterageLists.map(meterageList => [
        meterageList.meterageDisplayCode,
        meterageList.meterageFullName,
        meterageList.unit,
        meterageList.price,
        meterageList.pictureNum,
        meterageList.pictureAmount,
        undefined,
        meterageList.pictureNum,
        meterageList.pictureAmount,
        meterageList.currentYearPlanNum,
        meterageList.currentYearPlanAmount,
        meterageList.currentYearPlanPercent
      ])

      const hiddenArray = this.meterageLists.map(meterageList => [meterageList.meterageListGuid, meterageList.totalMonthStatNum])

      // 填充数据
      this.workbook.getActiveSheet().setRowCount(this.meterageLists.length + 2)
      this.workbook.getActiveSheet().setArray(2, 0, array)
      this.workbook.getActiveSheet().setArray(2, 20, hiddenArray)

      // 设置自动行高
      this.workbook.commandManager().execute({
        cmd: 'autoFitRow',
        sheetName: this.workbook.getActiveSheet().name(),
        rows: this.meterageLists.map((_, index) => ({
          row: index + 2
        })),
        columnHeader: false,
        autoFitType: GC.Spread.Sheets.AutoFitType.cell
      })

      this.$loading().close()
    }
  },
  computed: {
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      month: 'getMonth',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  async mounted () {
    await this.init()
  }
}
</script>

<style scoped lang="scss">
.g11 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  & .excel-container {
    flex: 1;
  }
}
</style>
